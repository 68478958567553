import { getKLines } from '@/binance';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { getRsi } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot, { ChartType } from '@/components/charts/crypto-plot';

export default function XxxRsiChartTemplate({
  articleId,
  coinFullName,
  coinAbbreviation,
  token,
  location,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [rsi, setRsi] = useState<number[]>([]);
  console.log(rsi);

  const [rsiLine, setRsiLine] = useState<ChartLine>({
    y: [],
    color: 'black',
    title: 'RSI',
  });

  const [line30, setLine30] = useState<ChartLine>({
    y: [],
    color: 'black',
    title: 'Oversold < 30%',
  });

  const [line70, setLine70] = useState<ChartLine>({
    y: [],
    color: 'black',
    title: 'Overbought > 70%',
  });

  useEffect(() => {
    getKLines(token, '1d', 100).then(function (df: DataFrame) {
      setDataFrame(df);
      const _rsi = getRsi(df.open, df.close, 14);
      setRsi(_rsi);

      setRsiLine({
        color: 'blue',
        title: 'RSI',
        y: _rsi,
      });

      setLine30({
        color: 'black',
        strokeWidth: 0.5,
        title: 'Oversold < 30%',
        y: df.close.slice().fill(30),
        dasharray: '5,5',
      });

      setLine70({
        color: 'black',
        strokeWidth: 0.5,
        title: 'Overbought > 70%',
        y: df.close.slice().fill(70),
        dasharray: '5,5',
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLowerCase(), 'rsi', 'chart']}
      articleId={articleId}
    >
      <section>
        <p>
          The {coinFullName} relative strength index (RSI) chart is a momentum
          indicator used in technical crypto analysis. {coinFullName} RSI
          measures the speed and magnitude of a {coinAbbreviation} recent price
          changes to evaluate overvalued or undervalued conditions in the price
          of that {coinFullName}.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[]}
          linesFooter={[rsiLine, line30, line70]}
          type={ChartType.Candlestick}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
          withFooter
        />
        <p>
          The {coinFullName} RSI is displayed as an oscillator (a line graph) on
          a scale of zero to 100. The indicator was developed by J. Welles
          Wilder Jr. and introduced in his seminal 1978 book, New Concepts in
          Technical Trading Systems.
        </p>
        <p>
          The {coinFullName} RSI chart can do more than point to overbought and
          oversold BTCs. It can also indicate securities that may be primed for
          a trend reversal or corrective pullback in price. It can signal when
          to buy and sell {coinFullName}. Traditionally, an RSI reading of 70 or
          above indicates an overbought situation. A reading of 30 or below
          indicates an oversold condition.
        </p>
        <h2>
          How the {coinFullName} Relative Strength Index (RSI) Chart Works
        </h2>
        <p>
          As a momentum indicator, the relative strength index compares a
          {coinFullName}&apos;s strength on days when prices go up to its
          strength on days when prices go down. Relating the result of this
          comparison to price action can give crypto traders an idea of how{' '}
          {coinAbbreviation}
          may perform. The RSI, used in conjunction with other technical
          indicators, can help crypto traders make better-informed trading
          decisions.
        </p>
        <h3>Calculating {coinFullName} RSI</h3>
        <p>
          The average gain or loss used in this calculation is the average
          percentage gain or loss during a look-back period. The formula uses a
          positive value for the average loss. Periods with {coinAbbreviation}{' '}
          price losses are counted as zero in the calculations of average gain.
          Periods with {coinAbbreviation}
          price increases are counted as zero in the calculations of average
          loss.
        </p>
        <p>
          The standard number of periods used to calculate the initial{' '}
          {coinFullName}
          RSI value is 14. For example, imagine the market closed higher seven
          out of the past 14 days with an average gain of 1%. The remaining
          seven days all closed lower with an average loss of -0.8%.
        </p>
        <p>
          Once there are 14 periods of data available, the second calculation
          can be done. Its purpose is to smooth the results so that the{' '}
          {coinFullName}
          RSI only nears 100 or zero in a strongly trending market.
        </p>
        <h3>Plotting {coinFullName} RSI Chart</h3>
        <p>
          After the {coinFullName} RSI is calculated, the RSI indicator can be
          plotted beneath an {coinFullName}&apos;s price chart, as shown below.
          The {coinFullName} RSI will rise as the number and size of up days
          increase. It will fall as the number and size of down days increase.
        </p>
        <p>
          As you can see in the above chart, the RSI indicator can stay in the
          overbought region for extended periods while the {coinAbbreviation} is
          in an uptrend. The indicator may also remain in oversold territory for
          a long time when the {coinAbbreviation} is in a downtrend. This can be
          confusing for new crypto analysts, but learning to use the indicator
          within the context of the prevailing trend will clarify these issues.
        </p>
        <h3>Why Is {coinFullName} RSI Chart Important?</h3>
        <ul>
          <li>
            Crypto traders can use {coinFullName} RSI chart to predict the price
            behavior of {coinAbbreviation}.
          </li>
          <li>
            It can help crypto traders validate trends and trend reversals.
          </li>
          <li>It can point to overbought and oversold coins.</li>
          <li>
            It can provide short-term {coinFullName} traders with buy and sell
            signals.
          </li>
        </ul>
        <h3>Using {coinFullName} RSI Chart With Trends</h3>
        <h2>Modify {coinFullName} RSI Chart Levels to Fit Trends</h2>
        <p>
          The primary trend of {coinFullName} is important to know to properly
          understand {coinFullName} RSI chart. For example, well-known crypto
          technician proposed that an oversold reading by the {coinFullName} RSI
          in an uptrend is probably much higher than 30. Likewise, an overbought
          reading during a downtrend is much lower than 70.
        </p>
        <p>
          As you can see in the following chart, during a downtrend, the{' '}
          {coinFullName}
          RSI peaks near 50 rather than 70. This could be seen by crypto traders
          as more reliably signaling bearish conditions.
        </p>
        <p>
          Many crypto investors create a horizontal trendline between the levels
          of 30 and 70 when a strong trend is in place to better identify the
          overall trend and extremes.
        </p>
        <p>
          On the other hand, modifying overbought or oversold {coinFullName} RSI
          levels when the price of {coinAbbreviation} is in a long-term
          horizontal channel or trading range (rather than a strong upward or
          downward trend) is usually unnecessary.
        </p>
        <p>
          The relative strength indicator chart is not as reliable in trending
          crypto markets as it is in trading ranges. In fact, most crypto
          traders understand that the signals given by the RSI in strong upward
          or downward trends often can be false.
        </p>
        <h3>Use Buy and Sell Signals That Fit Trends</h3>
        <p>
          A related concept focuses on trade signals and techniques that conform
          to the trend. In other words, using bullish signals primarily when the
          price is in a bullish trend and bearish signals primarily when{' '}
          {coinFullName}
          is in a bearish trend may help crypto traders to avoid the false
          alarms that the {coinFullName} RSI can generate in trending crypto
          markets.
        </p>
        <h2>Overbought or Oversold {coinFullName}</h2>
        <p>
          Generally, when the {coinFullName} RSI indicator crosses 30 on the{' '}
          {coinFullName}
          RSI chart, it is a bullish sign and when it crosses 70, it is a
          bearish sign. Put another way, one can interpret that {
            coinFullName
          }{' '}
          RSI values of 70 or above indicate that {coinAbbreviation} is becoming
          overbought or overvalued. It may be primed for a trend reversal or
          corrective price pullback. An {coinFullName} RSI reading of 30 or
          below indicates an oversold or undervalued condition.
        </p>
        <p>
          Overbought refers to {coinFullName} that trades at a price level above
          its true (or intrinsic) value. That means that {coinFullName}&apos;s
          priced above where it should be, according to practitioners of either
          technical or fundamental crypto analysis. Crypto traders who see
          indications that
          {coinFullName} is overbought may expect a price correction or trend
          reversal. Therefore, they may sell {coinAbbreviation}.
        </p>
        <p>
          The same idea applies to {coinFullName} that technical indicators such
          as the relative strength index highlight as oversold. It can be seen
          as trading at a lower price than it should. Crypto traders watching
          for just such an indication might expect a price correction or trend
          reversal and buy {coinAbbreviation}.
        </p>
        <h2>
          Interpretation of {coinFullName} RSI and {coinFullName} RSI Ranges
        </h2>
        <p>
          During trends, the {coinFullName} RSI readings may fall into a band or
          range. During an uptrend, the {coinFullName} RSI tends to stay above
          30 and should frequently hit 70. During a downtrend, it is rare to see
          the {coinFullName}
          RSI exceed 70. In fact, the indicator frequently hits 30 or below.
        </p>
        <p>
          These guidelines can help crypto traders determine {coinFullName}{' '}
          trend strength and spot potential reversals. For example, if the{' '}
          {coinFullName} RSI can&apos;t reach 70 on a number of consecutive
          price swings during an uptrend, but then drops below 30, the trend has
          weakened and could be reversing lower.
        </p>
        <p>
          The opposite is true for a downtrend. If the downtrend is unable to
          reach 30 or below and then rallies above 70, that downtrend has
          weakened and could be reversing to the upside. Trend lines and moving
          averages are helpful crypto technical tools to include when using the
          {coinFullName} RSI Chart in this way.
        </p>
        <h2>Example of {coinFullName} RSI Divergences</h2>
        <p>
          An {coinFullName} RSI divergence occurs when price moves in the
          opposite direction of the {coinFullName} RSI. In other words, a chart
          might display a change in momentum before a corresponding change in
          price.
        </p>
        <p>
          A bullish divergence occurs when the {coinFullName} RSI chart displays
          an oversold reading followed by a higher low that appears with lower
          lows in the price. This may indicate rising bullish momentum, and a
          break above oversold territory could be used to trigger a new long
          position.
        </p>
        <p>
          A bearish divergence occurs when the {coinFullName} RSI creates an
          overbought reading followed by a lower high that appears with higher
          highs on the price.
        </p>
        <p>
          As you can see in the following chart, a bullish divergence was
          identified when the {coinFullName} RSI formed higher lows as the price
          formed lower lows. This was a valid signal, but divergences can be
          rare when a stock is in a stable long-term trend. Using flexible
          oversold or overbought readings will help identify more potential
          signals.
        </p>
        <h2>Example of Positive-Negative {coinFullName} RSI Reversals</h2>
        <p>
          An additional {coinFullName} price-RSI relationship that crypto
          traders look for is positive and negative {coinFullName} RSI
          reversals. A positive {coinFullName}
          RSI reversal may take place once the RSI reaches a low that is lower
          than its previous low at the same time that a {coinFullName}&apos;s
          price reaches a low that is higher than its previous low price. Crypto
          traders would consider this formation a bullish sign and a buy signal.
        </p>
        <p>
          Conversely, a negative {coinFullName} RSI reversal may take place once
          the
          {coinFullName} RSI reaches a high that is higher that its previous
          high at the same time that a {coinFullName}&apos;s price reaches a
          lower high. This formation would be a bearish sign and a sell signal.
        </p>
        <h2>Example of {coinFullName} RSI Swing Rejections</h2>
        <p>
          Another crypto trading technique examines {coinFullName} RSI behavior
          when it is reemerging from overbought or oversold territory. This
          signal is called a bullish swing rejection and has four parts:
        </p>
        <ul>
          <li>The {coinFullName} RSI falls into oversold territory.</li>
          <li>The {coinFullName} RSI crosses back above 30.</li>
          <li>
            The {coinFullName} RSI forms another dip without crossing back into
            oversold territory.
          </li>
          <li>The {coinFullName} RSI then breaks its most recent high.</li>
        </ul>
        <p>
          As you can see in the following {coinFullName} RSI chart, the{' '}
          {coinFullName} RSI indicator was oversold, broke up through 30, and
          formed the rejection low that triggered the signal when it bounced
          higher. Using the
          {coinFullName} RSI in this way is very similar to drawing trend lines
          on a price {coinFullName} RSI chart.
        </p>
        <p>
          There is a bearish version of the swing rejection signal that is a
          mirror image of the bullish version. A bearish swing rejection also
          has four parts:
        </p>
        <ul>
          <li>The {coinFullName} RSI rises into overbought territory.</li>
          <li>The {coinFullName} RSI crosses back below 70.</li>
          <li>
            The {coinFullName} RSI chart forms another high without crossing
            back into overbought territory.
          </li>
          <li>The {coinFullName} RSI then breaks its most recent low.</li>
        </ul>
        <p>
          The following {coinFullName} RSI chart illustrates the bearish swing
          rejection signal. As with most trading techniques, this signal will be
          most reliable when it conforms to the prevailing long-term trend.
          Bearish signals during downward trends are less likely to generate
          false alarms.
        </p>
      </section>
    </PostTemplate>
  );
}
