import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxRsiChartTemplate from '@/templates/crypto/xxx-rsi-chart';

export default function EthereumRsiChart({ location }: GatsbyPageProps) {
  return (
    <XxxRsiChartTemplate
      coinAbbreviation="ETH"
      coinFullName="Ethereum"
      token={Token.ETHUSDT}
      location={location}
      articleId={ArticleList.ETHEREUM_RSI_CHART}
    ></XxxRsiChartTemplate>
  );
}
